import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Popup from '../../../../Popup/Popup';
import './ProjectPopup.css';
import {
  ClosePopupIcon,
  // LikeIcon,
  ShareIcon,
} from '../../../../../assets/icons/icons';
import { PROJECTS_LIST } from '../PortfolioList/helpers';
import FullScreenImage from '../../../../FullScreenImage/FullScreenImage';
import { PORTFOLIO_LINK } from '../../../../../assets/utils/constants';

function ProjectPopup() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(undefined);
  const [fullScreenSrc, setFullScreenSrc] = useState(undefined);

  useEffect(() => {
    if (!id) return;
    setData(PROJECTS_LIST.find((item) => Number(item.id) === Number(id)));
  }, [id]);

  function closePopup() {
    navigate(`/${PORTFOLIO_LINK}`);
  }

  function copyLink(evt) {
    evt.preventDefault();
    navigator.clipboard
      .writeText(window.location)
      .then(() => console.log('Copying link command was successful'))
      .catch((err) => console.log('Copy error: ', err));
  }

  return (
    <Popup className="project-popup" onClose={closePopup} isOpen={true}>
      <button
        className="project-popup__close-btn"
        type="button"
        onClick={closePopup}
      >
        <ClosePopupIcon
          mainClassName="project-popup__close-icon"
          strokeClassName="project-popup__close-icon-stroke"
        />
      </button>
      {data ? (
        <div className="project-popup__content">
          {data.date || data.location ? (
            <div className="project-popup__text-block">
              {data.date ? (
                <p className="project-popup__text">{data.date}</p>
              ) : null}
              {data.date && data.location ? (
                <p className="project-popup__text"> • </p>
              ) : null}
              {data.location ? (
                <p className="project-popup__text">{data.location}</p>
              ) : null}
            </div>
          ) : null}

          <div className="project-popup__heading">
            <p className="project-popup__title">{data.title}</p>
            <div className="project-popup__btns-block">
              {/* <button className="project-popup__btn" type="button">
                <LikeIcon
                  mainClassName="project-popup__btn-icon"
                  strokeClassName="project-popup__btn-icon-stroke"
                />
              </button> */}
              <button
                className="project-popup__btn"
                type="button"
                onClick={copyLink}
              >
                <ShareIcon
                  mainClassName="project-popup__btn-icon"
                  strokeClassName="project-popup__btn-icon-stroke"
                />
              </button>
            </div>
          </div>

          {data.description ? (
            <p className="project-popup__subtitle">{data.description}</p>
          ) : null}

          <ul className="project-popup__grid">
            {data.photos.map((item, i) => (
              <li className="project-popup__grid-item" key={i}>
                <img
                  className="project-popup__img"
                  src={item}
                  alt=""
                  onClick={() => setFullScreenSrc(item)}
                />
              </li>
            ))}
          </ul>

          <FullScreenImage
            src={fullScreenSrc}
            setSrc={setFullScreenSrc}
            withColor
          />
        </div>
      ) : null}
    </Popup>
  );
}

export default ProjectPopup;
