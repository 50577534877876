import { useEffect, useRef } from 'react';
import './Popup.css';

function Popup({ isOpen, setOpen, popupName, className, children, onClose }) {
  const overlay = useRef();

  function handleClose() {
    onClose
      ? onClose()
      : setOpen(
          popupName
            ? (prevState) => ({
                ...prevState,
                [popupName]: false,
              })
            : false
        );
    // setTimeout(() => {}, 300);
  }

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        handleClose();
      }
    }

    document.addEventListener('mousedown', handleOverlayClose);
    return () => {
      document.removeEventListener('mousedown', handleOverlayClose);
    };
  });

  return (
    <div
      className={`popup ${className ? className : ''} ${
        isOpen ? 'popup_opened' : ''
      }`}
      ref={overlay}
      // onTouchStart={handleClose}
    >
      <div className={`popup__container`}>{children}</div>
    </div>
  );
}

export default Popup;
