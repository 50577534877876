import { HeadingZamer } from '../../../Main/Custom/Vintashtory/Heading/Heading';
import Reviews from '../../../Main/Custom/Vintashtory/Reviews/Reviews';
import ZamerInfo from './ZamerInfo/ZamerInfo';
import './Zamer.css';

function Zamer() {
  return (
    <div className="zamer">
      <div className="zamer__content">
        <HeadingZamer />
        <ZamerInfo />
        <Reviews />
      </div>
    </div>
  );
}

export default Zamer;
