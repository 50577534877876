import img from '../../../../../assets/images/custom/zamer.webp';
import './ZamerInfo.css';

const textsList = [
  {
    text: 'Замеряем ширину проема окна',
  },
  {
    text: 'Прибавляем 10-15см',
    comment: '(штора должна перекрывать проем окна)',
  },
  {
    text: 'Получаем ширину готовой шторы',
  },
  {
    text: 'Замеряем высоту шторы от потолка до подоконника',
  },
  {
    text: 'Получаем максимальную высоту готовой шторы',
  },
  {
    text: 'Наши шторы высотой до 170 см',
  },
  {
    text: 'Если нужна штора высотой меньше 170 см,',
    comment: 'пишите в комментарии к заказу',
  },
];

const InfoIcon = ({ mainClassName, fillClassName }) => (
  <svg
    className={mainClassName}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      className={fillClassName}
      d="M15 30C12.951 30 11.0196 29.6078 9.20588 28.8235C7.40196 28.0392 5.80882 26.9559 4.42647 25.5735C3.04412 24.1912 1.96078 22.598 1.17647 20.7941C0.392157 18.9804 0 17.049 0 15C0 12.951 0.392157 11.0245 1.17647 9.22059C1.96078 7.40686 3.03922 5.80882 4.41176 4.42647C5.79412 3.04412 7.38726 1.96078 9.19118 1.17647C11.0049 0.392157 12.9363 0 14.9853 0C17.0343 0 18.9657 0.392157 20.7794 1.17647C22.5931 1.96078 24.1912 3.04412 25.5735 4.42647C26.9559 5.80882 28.0392 7.40686 28.8235 9.22059C29.6078 11.0245 30 12.951 30 15C30 17.049 29.6078 18.9804 28.8235 20.7941C28.0392 22.598 26.9559 24.1912 25.5735 25.5735C24.1912 26.9559 22.5931 28.0392 20.7794 28.8235C18.9755 29.6078 17.049 30 15 30ZM12.3529 23.5H18.5147C18.8284 23.5 19.0931 23.402 19.3088 23.2059C19.5245 23 19.6324 22.7402 19.6324 22.4265C19.6324 22.1324 19.5245 21.8824 19.3088 21.6765C19.0931 21.4608 18.8284 21.3529 18.5147 21.3529H16.6471V13.6618C16.6471 13.25 16.5441 12.9167 16.3382 12.6618C16.1422 12.4069 15.848 12.2794 15.4559 12.2794H12.6029C12.299 12.2794 12.0392 12.3873 11.8235 12.6029C11.6078 12.8088 11.5 13.0588 11.5 13.3529C11.5 13.6667 11.6078 13.9265 11.8235 14.1324C12.0392 14.3284 12.299 14.4265 12.6029 14.4265H14.2206V21.3529H12.3529C12.0392 21.3529 11.7745 21.4608 11.5588 21.6765C11.3431 21.8824 11.2353 22.1324 11.2353 22.4265C11.2353 22.7402 11.3431 23 11.5588 23.2059C11.7745 23.402 12.0392 23.5 12.3529 23.5ZM14.8676 9.76471C15.4265 9.76471 15.8922 9.57353 16.2647 9.19118C16.6471 8.79902 16.8382 8.32843 16.8382 7.77941C16.8382 7.22059 16.6471 6.75 16.2647 6.36765C15.8922 5.97549 15.4265 5.77941 14.8676 5.77941C14.3186 5.77941 13.848 5.97549 13.4559 6.36765C13.0735 6.75 12.8824 7.22059 12.8824 7.77941C12.8824 8.32843 13.0735 8.79902 13.4559 9.19118C13.848 9.57353 14.3186 9.76471 14.8676 9.76471Z"
      fill="#C0A274"
    />
  </svg>
);

function ZamerInfo() {
  return (
    <div className="zamer-info">
      <img className="zamer-info__img" src={img} alt="" />
      <div className="zamer-info__text-block">
        <ul className="zamer-info__list">
          {textsList.map((item, i) => (
            <li className="zamer-info__item" key={i}>
              <span className="zamer-info__number">{i + 1}</span>
              <p className="zamer-info__text">
                {item.text}
                <br />
                {item.comment ? (
                  <span className="zamer-info__text_size_small">
                    {item.comment}
                  </span>
                ) : null}
              </p>
            </li>
          ))}
        </ul>

        <div className="zamer-info__block">
          <div className="zamer-info__item">
            <InfoIcon
              mainClassName="zamer-info__icon"
              fillClassName="zamer-info__icon-fill"
            />
            <p className="zamer-info__text">
              Штора устанавливается к стене над оконным проёмом или к потолку
              универсальными кронштейнами.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ZamerInfo;
