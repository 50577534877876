import { useColor } from 'color-thief-react';
// import { CloseIcon } from '../../assets/icons/icons';
// import ImageWithShadow from '../ImageWithShadow/ImageWithShadow';
import './FullScreenImage.css';

const FullScreenImage = ({ src, setSrc, withColor = false }) => {
  const { data } = useColor(src, 'hex');

  return (
    <div
      className={`full-screen-image ${src ? 'full-screen-image_active' : ''}`}
    >
      {src ? (
        <img className={'full-screen-image__img'} src={src} alt="" />
      ) : null}
      <div
        className="full-screen-image__bg"
        style={withColor ? { backgroundColor: data, opacity: 0.4 } : {}}
        onClick={() => {
          setSrc(undefined);
        }}
      />
      <div
        className="full-screen-image__close"
        onClick={() => {
          setSrc(undefined);
        }}
      >
        {/* <CloseIcon mainClassName={'full-screen-image__close-icon'} strokeClassName={'full-screen-image__close-icon-stroke'} /> */}
      </div>
    </div>
  );
};

export default FullScreenImage;
