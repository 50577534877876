import { useContext, useState } from 'react';
import validator from 'validator';
import Input from '../../../../Input/Input';
import Popup from '../../../../Popup/Popup';
import './ContactPopup.css';
import Checkbox from '../../../../Checkbox/Checkbox';
import { sendTextTgBot } from '../../../../../assets/utils/utils';
import { CHAT_ID } from '../../../../../assets/utils/constants';
import MiniPreloader from '../../../../MiniPreloader/MiniPreloader';
import { ContactContext } from '../../../../../assets/contexts/contactContext';
import { ClosePopupIcon } from '../../../../../assets/icons/icons';

const initialValues = {
  name: '',
  phone: '',
  email: '',
  agreement: false,
};

const initialValuesValidity = {
  name: {},
  phone: {},
  email: {},
  agreement: {},
};

function ContactPopup() {
  const { isContactPopupOpen: isOpen, setContactPopupOpen: setOpen } =
    useContext(ContactContext);
  const [values, setValues] = useState(initialValues);
  const [valuesValidity, setValuesValidity] = useState(initialValuesValidity);
  const [preloaders, setPreloaders] = useState(false);
  const [step, setStep] = useState(0);

  const isSubmitDisabled = !Object.values(valuesValidity).every(
    (item) => item.validState
  );

  function closePopup() {
    setOpen(false);
    setTimeout(() => {
      setValues(initialValues);
      setValuesValidity(initialValuesValidity);
      setStep(0);
    }, 300);
  }

  function handleChange(e) {
    const input = e.target;
    const type = input.type;
    let value = type === 'checkbox' ? input.checked : input.value;
    const name = input.name;

    switch (name) {
      case 'phone':
        let inputValue = e.target.value.replace(/\D/g, '');
        let formattedInputValue = '';
        if (!inputValue) {
          setValues((prevValue) => ({
            ...prevValue,
            [name]: '',
          }));
          setValuesValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: 'Можно вводить только цифры',
              validState: false,
            },
          }));
        } else {
          if (['7', '8', '9'].indexOf(inputValue[0]) > -1) {
            setValuesValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: '',
                validState: false,
              },
            }));
            if (inputValue[0] === '9') inputValue = '7' + inputValue;

            let firstSimbols = inputValue[0] === '8' ? '8' : '+7';
            formattedInputValue = firstSimbols + ' ';

            if (inputValue.length > 1) {
              formattedInputValue += '(' + inputValue.substring(1, 4);
            }
            if (inputValue.length >= 5) {
              formattedInputValue += ') ' + inputValue.substring(4, 7);
            }
            if (inputValue.length >= 8) {
              formattedInputValue += '-' + inputValue.substring(7, 9);
            }
            if (inputValue.length >= 10) {
              formattedInputValue += '-' + inputValue.substring(9, 11);
            }
            if (inputValue.length >= 11) {
              setValuesValidity((prevValue) => ({
                ...prevValue,
                [name]: {
                  errorMessage: '',
                  validState: true,
                },
              }));
            } else {
              setValuesValidity((prevValue) => ({
                ...prevValue,
                [name]: {
                  errorMessage: '',
                  validState: false,
                },
              }));
            }
          } else {
            formattedInputValue = '+' + inputValue.substring(0, 16);
            if (inputValue.length >= 11) {
              setValuesValidity((prevValue) => ({
                ...prevValue,
                [name]: {
                  errorMessage: '',
                  validState: true,
                },
              }));
            } else {
              setValuesValidity((prevValue) => ({
                ...prevValue,
                [name]: {
                  errorMessage: 'invalid frone',
                  validState: false,
                },
              }));
            }
          }

          setValues((prevValue) => ({
            ...prevValue,
            [name]: formattedInputValue,
          }));
        }

        break;

      case 'email':
        if (!value) {
          setValuesValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: '',
              validState: false,
            },
          }));
        }
        if (value.length >= 2) {
          if (validator.isEmail(value)) {
            setValuesValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: '',
                validState: true,
              },
            }));
          } else {
            setValuesValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: !e.target.validity.valid
                  ? e.target.validationMessage
                  : 'Invalid email',
                validState: false,
              },
            }));
          }
        }
        setValues((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
        break;

      default:
        setValues((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name]: {
            errorMessage: '',
            validState: Boolean(value),
          },
        }));
        break;
    }
  }

  function handlePhoneDelete(e) {
    if (e.keyCode === 8 && e.target.value.replace(/\D/g, '').length === 1) {
      setValues((prevValue) => ({
        ...prevValue,
        phone: '',
      }));
    }
    if (e.keyCode === 8 && e.target.value.replace(/\D/g, '').length < 11) {
      setValuesValidity((prevValue) => ({
        ...prevValue,
        phone: {
          errorMessage: '',
          validState: false,
        },
      }));
    }
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (isSubmitDisabled) return;

    sendTextTgBot(
      CHAT_ID,
      `________
Оставить заявку

Name: ${values.name}
Phone: ${values.phone}
Email: ${values.email}
________`,
      setPreloaders
    )
      .then(() => setStep((prevVal) => prevVal + 1))
      .catch((err) => console.log(err));
  };

  return (
    <Popup className="contact-popup" onClose={closePopup} {...{ isOpen }}>
      <button
        className="contact-popup__close-btn"
        type="button"
        onClick={closePopup}
      >
        <ClosePopupIcon
          mainClassName="contact-popup__close-icon"
          strokeClassName="contact-popup__close-icon-stroke"
        />
      </button>
      <div className="contact-popup__content">
        <p className="contact-popup__logo">VINTASHTORY</p>
        {step === 0 ? (
          <>
            <p className="contact-popup__title">Оставить заявку</p>
            <form className="contact-popup__form" onSubmit={handleSubmit}>
              <div className="contact-popup__inputs">
                <Input
                  label="Имя"
                  name="name"
                  value={values.name}
                  handleChange={handleChange}
                />
                <Input
                  label="Телефон"
                  name="phone"
                  inputMode="tel"
                  value={values.phone}
                  handleChange={handleChange}
                  onKeyDown={handlePhoneDelete}
                />
                <Input
                  label="Эл. почта"
                  name="email"
                  inputMode="email"
                  value={values.email}
                  handleChange={handleChange}
                />
              </div>

              <div className="contact-popup__checkbox">
                <Checkbox
                  label="Я согласен на обработку персональных данных"
                  name="agreement"
                  checked={values.agreement}
                  onChange={handleChange}
                />
              </div>

              <button
                className={`contact-popup__submit-btn heading__btn heading__btn_type_link ${
                  isSubmitDisabled ? 'contact-popup__submit-btn_disabled' : ''
                }`}
                type="submit"
              >
                {!preloaders ? 'Отправить' : <MiniPreloader isLinkColor />}
              </button>
            </form>
          </>
        ) : (
          <>
            <p className="contact-popup__title contact-popup__title_type_success">
              Наши операторы скоро свяжутся с&nbsp;вами
            </p>
            <button
              className="contact-popup__submit-btn heading__btn heading__btn_type_link"
              type="button"
              onClick={closePopup}
            >
              OK
            </button>
          </>
        )}
      </div>
    </Popup>
  );
}

export default ContactPopup;
