import project1_photo1 from '../../../../../assets/images/custom/portfolio/project1/photo1.jpg';
import project1_photo2 from '../../../../../assets/images/custom/portfolio/project1/photo2.jpg';
import project1_photo3 from '../../../../../assets/images/custom/portfolio/project1/photo3.jpg';

import project2_photo1 from '../../../../../assets/images/custom/portfolio/project2/photo1.jpg';
import project2_photo2 from '../../../../../assets/images/custom/portfolio/project2/photo2.jpg';
import project2_photo3 from '../../../../../assets/images/custom/portfolio/project2/photo3.jpg';
import project2_photo4 from '../../../../../assets/images/custom/portfolio/project2/photo4.jpg';
import project2_photo5 from '../../../../../assets/images/custom/portfolio/project2/photo5.jpg';
import project2_photo6 from '../../../../../assets/images/custom/portfolio/project2/photo6.jpg';

import project3_photo1 from '../../../../../assets/images/custom/portfolio/project3/photo1.jpg';
import project3_photo2 from '../../../../../assets/images/custom/portfolio/project3/photo2.jpg';
import project3_photo3 from '../../../../../assets/images/custom/portfolio/project3/photo3.jpg';

import project4_photo1 from '../../../../../assets/images/custom/portfolio/project4/photo1.jpg';
import project4_photo2 from '../../../../../assets/images/custom/portfolio/project4/photo2.jpg';
import project4_photo3 from '../../../../../assets/images/custom/portfolio/project4/photo3.jpg';
import project4_photo4 from '../../../../../assets/images/custom/portfolio/project4/photo4.jpg';

import project5_photo1 from '../../../../../assets/images/custom/portfolio/project5/photo1.jpg';
import project5_photo2 from '../../../../../assets/images/custom/portfolio/project5/photo2.jpg';
import project5_photo3 from '../../../../../assets/images/custom/portfolio/project5/photo3.jpg';
import project5_photo4 from '../../../../../assets/images/custom/portfolio/project5/photo4.jpg';

import project6_photo1 from '../../../../../assets/images/custom/portfolio/project6/photo1.jpg';
import project6_photo2 from '../../../../../assets/images/custom/portfolio/project6/photo2.jpg';
import project6_photo3 from '../../../../../assets/images/custom/portfolio/project6/photo3.jpg';

import project7_photo1 from '../../../../../assets/images/custom/portfolio/project7/photo1.jpg';
import project7_photo2 from '../../../../../assets/images/custom/portfolio/project7/photo2.jpg';
import project7_photo3 from '../../../../../assets/images/custom/portfolio/project7/photo3.jpg';

import project8_photo1 from '../../../../../assets/images/custom/portfolio/project8/photo1.jpg';
import project8_photo2 from '../../../../../assets/images/custom/portfolio/project8/photo2.jpg';
import project8_photo3 from '../../../../../assets/images/custom/portfolio/project8/photo3.jpg';
import project8_photo4 from '../../../../../assets/images/custom/portfolio/project8/photo4.jpg';
import project8_photo5 from '../../../../../assets/images/custom/portfolio/project8/photo5.jpg';

import project9_photo1 from '../../../../../assets/images/custom/portfolio/project9/photo1.jpg';
import project9_photo2 from '../../../../../assets/images/custom/portfolio/project9/photo2.jpg';
import project9_photo3 from '../../../../../assets/images/custom/portfolio/project9/photo3.jpg';
import project9_photo4 from '../../../../../assets/images/custom/portfolio/project9/photo4.jpg';
import project9_photo5 from '../../../../../assets/images/custom/portfolio/project9/photo5.jpg';

import project10_photo1 from '../../../../../assets/images/custom/portfolio/project10/photo1.jpg';
import project10_photo2 from '../../../../../assets/images/custom/portfolio/project10/photo2.jpg';
import project10_photo3 from '../../../../../assets/images/custom/portfolio/project10/photo3.jpg';
import project10_photo4 from '../../../../../assets/images/custom/portfolio/project10/photo4.jpg';
import project10_photo5 from '../../../../../assets/images/custom/portfolio/project10/photo5.jpg';

import project11_photo1 from '../../../../../assets/images/custom/portfolio/project11/photo1.jpg';
import project11_photo2 from '../../../../../assets/images/custom/portfolio/project11/photo2.jpg';
import project11_photo3 from '../../../../../assets/images/custom/portfolio/project11/photo3.jpg';
import project11_photo4 from '../../../../../assets/images/custom/portfolio/project11/photo4.jpg';
import project11_photo5 from '../../../../../assets/images/custom/portfolio/project11/photo5.jpg';

import project12_photo1 from '../../../../../assets/images/custom/portfolio/project12/photo1.jpg';
import project12_photo2 from '../../../../../assets/images/custom/portfolio/project12/photo2.jpg';
import project12_photo3 from '../../../../../assets/images/custom/portfolio/project12/photo3.jpg';
import project12_photo4 from '../../../../../assets/images/custom/portfolio/project12/photo4.jpg';
import project12_photo5 from '../../../../../assets/images/custom/portfolio/project12/photo5.jpg';
import project12_photo6 from '../../../../../assets/images/custom/portfolio/project12/photo6.jpg';

import project13_photo1 from '../../../../../assets/images/custom/portfolio/project13/photo1.jpg';

import project14_photo1 from '../../../../../assets/images/custom/portfolio/project14/photo1.jpg';
import project14_photo2 from '../../../../../assets/images/custom/portfolio/project14/photo2.jpg';
import project14_photo3 from '../../../../../assets/images/custom/portfolio/project14/photo3.jpg';
import project14_photo4 from '../../../../../assets/images/custom/portfolio/project14/photo4.jpg';
import project14_photo5 from '../../../../../assets/images/custom/portfolio/project14/photo5.jpg';

import project15_photo1 from '../../../../../assets/images/custom/portfolio/project15/photo1.jpg';
import project15_photo2 from '../../../../../assets/images/custom/portfolio/project15/photo2.jpg';
import project15_photo3 from '../../../../../assets/images/custom/portfolio/project15/photo3.jpg';
import project15_photo4 from '../../../../../assets/images/custom/portfolio/project15/photo4.jpg';

import project16_photo1 from '../../../../../assets/images/custom/portfolio/project16/photo1.jpg';
import project16_photo2 from '../../../../../assets/images/custom/portfolio/project16/photo2.jpg';
import project16_photo3 from '../../../../../assets/images/custom/portfolio/project16/photo3.jpg';

import project17_photo1 from '../../../../../assets/images/custom/portfolio/project17/photo1.jpg';

import project18_photo1 from '../../../../../assets/images/custom/portfolio/project18/photo1.jpg';
import project18_photo2 from '../../../../../assets/images/custom/portfolio/project18/photo2.jpg';
import project18_photo3 from '../../../../../assets/images/custom/portfolio/project18/photo3.jpg';
import project18_photo4 from '../../../../../assets/images/custom/portfolio/project18/photo4.jpg';
import project18_photo5 from '../../../../../assets/images/custom/portfolio/project18/photo5.jpg';
import project18_photo6 from '../../../../../assets/images/custom/portfolio/project18/photo6.jpg';

import project19_photo1 from '../../../../../assets/images/custom/portfolio/project19/photo1.jpg';
import project19_photo2 from '../../../../../assets/images/custom/portfolio/project19/photo2.jpg';
import project19_photo3 from '../../../../../assets/images/custom/portfolio/project19/photo3.jpg';
import project19_photo4 from '../../../../../assets/images/custom/portfolio/project19/photo4.jpg';

import project20_photo1 from '../../../../../assets/images/custom/portfolio/project20/photo1.jpg';
import project20_photo2 from '../../../../../assets/images/custom/portfolio/project20/photo2.jpg';
import project20_photo3 from '../../../../../assets/images/custom/portfolio/project20/photo3.jpg';
import project20_photo4 from '../../../../../assets/images/custom/portfolio/project20/photo4.jpg';
import project20_photo5 from '../../../../../assets/images/custom/portfolio/project20/photo5.jpg';

import project21_photo1 from '../../../../../assets/images/custom/portfolio/project21/photo1.jpg';
import project21_photo2 from '../../../../../assets/images/custom/portfolio/project21/photo2.jpg';
import project21_photo3 from '../../../../../assets/images/custom/portfolio/project21/photo3.jpg';

import project22_photo1 from '../../../../../assets/images/custom/portfolio/project22/photo1.jpg';
import project22_photo2 from '../../../../../assets/images/custom/portfolio/project22/photo2.jpg';
import project22_photo3 from '../../../../../assets/images/custom/portfolio/project22/photo3.jpg';
import project22_photo4 from '../../../../../assets/images/custom/portfolio/project22/photo4.jpg';
import project22_photo5 from '../../../../../assets/images/custom/portfolio/project22/photo5.jpg';
import project22_photo6 from '../../../../../assets/images/custom/portfolio/project22/photo6.jpg';

export const PROJECTS_LIST = [
  {
    id: 1,
    title: 'Римская штора Зигзаги',
    photos: [project1_photo1, project1_photo2, project1_photo3],
  },
  {
    id: 2,
    title: 'Оформление текстилем банкетного зала в кафе',
    photos: [project2_photo1, project2_photo2, project2_photo3, project2_photo4, project2_photo5, project2_photo6],
  },
  {
    id: 3,
    title: 'Римская штора «Галька» с подушкой',
    photos: [project3_photo1, project3_photo2, project3_photo3],
  },
  {
    id: 4,
    title: 'Покрывало и подушки 40*60 см-2 шт',
    photos: [project4_photo1, project4_photo2, project4_photo3, project4_photo4],
  },
  {
    id: 5,
    title: 'Римская штора Геометрия',
    photos: [project5_photo1, project5_photo2, project5_photo3, project5_photo4],
  },
  {
    id: 6,
    title: 'Комплект штор с бордюром',
    photos: [project6_photo1, project6_photo2, project6_photo3],
  },
  {
    id: 7,
    title: 'Оформление кабинета',
    location: 'Пенза',
    photos: [project7_photo1, project7_photo2, project7_photo3],
  },
  {
    id: 8,
    title: 'Оформление зала в лицее Звезда',
    location: 'Саратов',
    photos: [project8_photo1, project8_photo2, project8_photo3, project8_photo4, project8_photo5],
  },
  {
    id: 9,
    title: 'Оформление приемной начальника',
    location: 'Пенза',
    photos: [project9_photo1, project9_photo2, project9_photo3, project9_photo4, project9_photo5],
  },
  {
    id: 10,
    title: 'Оформление кабинета',
    location: 'Саратов',
    photos: [project10_photo1, project10_photo2, project10_photo3, project10_photo4, project10_photo5],
  },
  {
    id: 11,
    title: 'Оформление кабинета в лицее Звезда',
    location: 'Саратов',
    photos: [project11_photo1, project11_photo2, project11_photo3, project11_photo4, project11_photo5],
  },
  {
    id: 12,
    title: 'Оформление каюты корабля',
    photos: [project12_photo1, project12_photo2, project12_photo3, project12_photo4, project12_photo5, project12_photo6],
  },
  {
    id: 13,
    title: 'Мягкая римская штора изо льна',
    photos: [project13_photo1],
  },
  {
    id: 14,
    title: 'Римская штора Комиксы',
    photos: [project14_photo1, project14_photo2, project14_photo3, project14_photo4, project14_photo5],
  },
  {
    id: 15,
    title: 'Римская штора день/ночь Листья',
    photos: [project15_photo1, project15_photo2, project15_photo3, project15_photo4],
  },
  {
    id: 16,
    title: 'Римская штора день/ночь',
    photos: [project16_photo1, project16_photo2, project16_photo3],
  },
  {
    id: 17,
    title: 'Римская штора',
    photos: [project17_photo1],
  },
  {
    id: 18,
    title: 'Комплект штор Принцесса',
    photos: [project18_photo1, project18_photo2, project18_photo3, project18_photo4, project18_photo5, project18_photo6],
  },
  {
    id: 19,
    title: 'Комплект штор АДЕКО',
    photos: [project19_photo1, project19_photo2, project19_photo3, project19_photo4],
  },
  {
    id: 20,
    title: 'Римская штора Ромбы Бархат',
    photos: [project20_photo1, project20_photo2, project20_photo3, project20_photo4, project20_photo5],
  },
  {
    id: 21,
    title: 'Римская штора Фламинго',
    photos: [project21_photo1, project21_photo2, project21_photo3],
  },
  {
    id: 22,
    title: 'Комплект штор для кухни-гостиной Растения',
    photos: [project22_photo1, project22_photo2, project22_photo3, project22_photo4, project22_photo5, project22_photo6],
  },
];
