import './Checkbox.css';

const CheckIcon = ({ mainClassName, fillClassName, checkFillClassName }) => (
  <svg
    className={mainClassName}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      className={fillClassName}
      d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z"
      fill="#C0A274"
    />
    <path
      className={checkFillClassName}
      d="M6.94874 12.5C6.5652 12.5 6.24965 12.363 6.00209 12.0891L3.30858 9.08416C3.19003 8.96205 3.10809 8.84488 3.06276 8.73267C3.02092 8.61716 3 8.49505 3 8.36634C3 8.08251 3.10112 7.84653 3.30335 7.65842C3.50558 7.4703 3.76185 7.37624 4.07218 7.37624C4.40342 7.37624 4.67887 7.4967 4.89854 7.73762L6.92782 10.0396L10.9812 4.01485C11.1102 3.82673 11.2462 3.69472 11.3891 3.61881C11.5356 3.5396 11.7134 3.5 11.9226 3.5C12.2259 3.5 12.4805 3.59406 12.6862 3.78218C12.8954 3.967 13 4.20132 13 4.48515C13 4.58746 12.9808 4.69472 12.9425 4.80693C12.9041 4.91584 12.8448 5.0264 12.7646 5.13861L7.93724 12.0248C7.71409 12.3416 7.38459 12.5 6.94874 12.5Z"
      fill="white"
    />
  </svg>
);

function Checkbox({ onChange, ...props }) {
  const { name, label, checked, error } = props;

  return (
    <div className="checkbox">
      <div className="checkbox__container">
        <label className="checkbox__box" htmlFor={name}>
          <input
            className="checkbox__invisible-input"
            id={name}
            name={name}
            type="checkbox"
            value={checked}
            checked={checked}
            onChange={onChange}
          />
          <span
            className={`checkbox__pseudo-item ${
              checked ? 'checkbox__pseudo-item_checked' : ''
            }`}
          >
            {CheckIcon({
              mainClassName: 'checkbox__check-icon',
              fillClassName: 'checkbox__icon-fill',
              checkFillClassName: 'checkbox__check-icon-fill',
            })}
          </span>
        </label>
        <span className="checkbox__label">{label}</span>
      </div>

      {error !== undefined && Boolean(error.errorMessage) ? (
        <span className="checkbox__error">{error.errorMessage}</span>
      ) : null}
    </div>
  );
}

export default Checkbox;
