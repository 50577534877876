import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Pagination } from 'swiper';
import img_1 from '../../../../../assets/images/custom/reviews/review_1.jpg';
import img_2 from '../../../../../assets/images/custom/reviews/review_2.jpg';
import img_3 from '../../../../../assets/images/custom/reviews/review_3.jpg';

import './Reviews.css';
import 'swiper/css/pagination';
import ReviewCard from './ReviewCard/ReviewCard';

const SLIDES_PER_VIEW = 3;
const EXAMPLE = [
  {
    name: 'Вера',
    city: 'г. Южно-Сахалинск',
    text: 'Светлана, спасибо большое! Вы – волшебница!!! Получилось просто супер!',
    image: img_1,
  },
  {
    name: 'Кристина',
    city: 'г. Новокуйбышевск',
    text: 'Наконец-то высылаю Вам фото нашего окна)) Еще раз огромное Вам спасибо!!! Вы большая молодец!!! Шторы супер, я не перестаю ими любоваться))',
    image: img_2,
  },
  {
    name: 'Татьяна',
    city: 'г. Сургут',
    text: 'Получили от вас наш заказ! Очень довольны. Большое вам спасибо за вашу работу! Теперь ваша красота будет и в Сургуте!!!',
    image: img_3,
  },
];

const ArrowIcon = ({ mainClassName, fillClassName, strokeClassName }) => (
  <svg
    className={mainClassName}
    xmlns="http://www.w3.org/2000/svg"
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
  >
    <g filter="url(#filter0_d_17_232)">
      <path
        className={fillClassName}
        d="M10 29C10 15.7452 20.7452 5 34 5C47.2548 5 58 15.7452 58 29C58 42.2548 47.2548 53 34 53C20.7452 53 10 42.2548 10 29Z"
        fill="white"
      />
      <path
        className={strokeClassName}
        d="M38 21L30 29L38 37"
        stroke="#C0A274"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_17_232"
        x="0"
        y="0"
        width="68"
        height="68"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.762238 0 0 0 0 0.781437 0 0 0 0 0.849512 0 0 0 0.42 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_17_232"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_17_232"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

function Reviews() {
  const [swiper, set_swiper] = useState({});
  const [slideActive, setSlideActive] = useState(0);

  return (
    <div className="reviews">
      <div className="reviews__container">
        <p className="reviews__title">Отзывы клиентов</p>
        <div className="reviews__swiper-box">
          <Swiper
            className="reviews__swiper"
            slidesPerView={1.05}
            modules={[Mousewheel, Pagination]}
            pagination={{
              clickable: true,
              horizontalClass: 'reviews__swiper-pagination',
              bulletClass: 'reviews__swiper-bullet',
              bulletActiveClass: 'reviews__swiper-bullet_type_active',
            }}
            grabCursor={true}
            mousewheel={{ forceToAxis: true }}
            preventInteractionOnTransition={true}
            onInit={(evt) => set_swiper(evt)}
            onSlideChange={() => setSlideActive(swiper?.activeIndex)}
            breakpoints={{
              1001: {
                slidesPerView: SLIDES_PER_VIEW,
                pagination: false,
              },
              751: {
                slidesPerView: SLIDES_PER_VIEW,
              },
            }}
          >
            {EXAMPLE.map((item, i) => {
              return (
                <SwiperSlide key={i} className="reviews__slide">
                  <ReviewCard data={item} />
                </SwiperSlide>
              );
            })}
          </Swiper>

          <button
            className={`reviews__swiper-btn reviews__swiper-btn_type_prev ${
              EXAMPLE.length <= SLIDES_PER_VIEW
                ? 'reviews__swiper-btn_hidden'
                : ''
            } ${slideActive === 0 ? 'reviews__swiper-btn_type_opacity' : ''}`}
            type="button"
            onClick={() => {
              if (swiper) swiper.slidePrev();
            }}
          >
            <ArrowIcon
              mainClassName="reviews__arrow-icon"
              fillClassName="reviews__arrow-icon-fill"
              strokeClassName="reviews__arrow-icon-stroke"
            />
          </button>
          <button
            className={`reviews__swiper-btn reviews__swiper-btn_type_next ${
              EXAMPLE.length <= SLIDES_PER_VIEW
                ? 'reviews__swiper-btn_hidden'
                : ''
            } ${
              slideActive === EXAMPLE.length - SLIDES_PER_VIEW
                ? 'reviews__swiper-btn_type_opacity'
                : ''
            }`}
            type="button"
            onClick={() => {
              if (swiper) swiper.slideNext();
            }}
          >
            <ArrowIcon
              mainClassName="reviews__arrow-icon reviews__arrow-icon_type_reverse"
              fillClassName="reviews__arrow-icon-fill"
              strokeClassName="reviews__arrow-icon-stroke"
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
