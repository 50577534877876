import './ReviewCard.css';

function ReviewCard({ data }) {
  return (
    <div className="review-card">
      <img className="review-card__img" src={data.image} alt="" />
      <div className="review-card__text-block">
        <p className="review-card__title">{data.name}</p>
        <p className="review-card__city">{data.city}</p>
        <p className="review-card__text">{data.text}</p>
      </div>
    </div>
  );
}

export default ReviewCard;
