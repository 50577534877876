import { Link } from 'react-router-dom';
import card1 from '../../../../../assets/images/custom/card1.webp';
import card2 from '../../../../../assets/images/custom/card2.webp';
import './CardLinks.css';
import { CATALOG_MAIN_LINK, PORTFOLIO_LINK } from '../../../../../assets/utils/constants';

const CARDS = [
  {
    img: card2,
    title: 'Каталог',
    link: `/${CATALOG_MAIN_LINK}`,
  },
  {
    img: card1,
    title: 'Портфолио',
    link: `/${PORTFOLIO_LINK}`,
  },
];

function CardLinks() {
  return (
    <ul className="card-links">
      {CARDS.map((item, i) => (
        <li className="card-links__item" key={i}>
          <Link className="card-links__link" to={item.link}>
            <img className="card-links__img" src={item.img} alt="" />
            <div className="card-links__text-block">
              <p className="card-links__title">{item.title}</p>
              <p className="card-links__text">
                Смотреть {item.title.toLowerCase()} →
              </p>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default CardLinks;
