import { Route, Routes } from 'react-router-dom';
import { HeadingPortfolio } from '../../../Main/Custom/Vintashtory/Heading/Heading';
import './Portfolio.css';
import PortfolioList from './PortfolioList/PortfolioList';
import ProjectPopup from './ProjectPopup/ProjectPopup';

function Portfolio() {
  return (
    <div className="portfolio">
      <div className="portfolio__content">
        <HeadingPortfolio />
        <PortfolioList />
      </div>

      <Routes>
        <Route path="/:id" element={<ProjectPopup />} />
      </Routes>
    </div>
  );
}

export default Portfolio;
