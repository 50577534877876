import { Link } from 'react-router-dom';
import { useColor } from 'color-thief-react';
import './PortfolioCard.css';
import {
  // LikeIcon,
  ShareIcon,
} from '../../../../../assets/icons/icons';
import { PORTFOLIO_LINK } from '../../../../../assets/utils/constants';

function PortfolioCard({ item }) {
  const { photos, title } = item;
  const IMAGE_URL = photos[0];
  const { data } = useColor(IMAGE_URL, 'hex');
  const { protocol, host } = window.location;
  const link = `/${PORTFOLIO_LINK}/${item.id}`;

  function copyLink(evt) {
    evt.preventDefault();
    navigator.clipboard
      .writeText(`${protocol}//${host}${link}`)
      .then(() => console.log('Copying link command was successful'))
      .catch((err) => console.log('Copy error: ', err));
  }

  return (
    <div className="portfolio-card" style={{ backgroundColor: data }}>
      <Link className="portfolio-card__link" to={link}>
        <img className="portfolio-card__img" src={IMAGE_URL} alt="" />

        <div className="portfolio-card__info">
          {item.date || item.location ? (
            <div className="portfolio-card__text-block">
              {item.date ? (
                <p className="portfolio-card__text">{item.date}</p>
              ) : null}
              {item.date && item.location ? (
                <p className="portfolio-card__text"> • </p>
              ) : null}
              {item.location ? (
                <p className="portfolio-card__text">{item.location}</p>
              ) : null}
            </div>
          ) : null}

          <p className="portfolio-card__title">{title}</p>

          {item.description ? (
            <p className="portfolio-card__subtitle">{item.description}</p>
          ) : null}
        </div>

        <div className="portfolio-card__btns-block">
          {/* <button className="portfolio-card__btn" type="button">
          <LikeIcon
            mainClassName="portfolio-card__btn-icon"
            strokeClassName="portfolio-card__btn-icon-stroke"
          />
        </button> */}
          <button
            className="portfolio-card__btn"
            type="button"
            onClick={copyLink}
          >
            <ShareIcon
              mainClassName="portfolio-card__btn-icon"
              strokeClassName="portfolio-card__btn-icon-stroke"
            />
          </button>
        </div>
      </Link>
    </div>
  );
}

export default PortfolioCard;
