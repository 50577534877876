import { useContext } from 'react';
import { Link } from 'react-router-dom';
import mainImg from '../../../../../assets/images/custom/heading-img.webp';
import zamerImg from '../../../../../assets/images/custom/zamer-heading-img.webp';
import portfolioImg from '../../../../../assets/images/custom/portfolio-heading-img.webp';
import './Heading.css';
import { ContactContext } from '../../../../../assets/contexts/contactContext';
import { CATALOG_MAIN_LINK } from '../../../../../assets/utils/constants';

export function HeadingMain() {
  const { setContactPopupOpen: setOpen } = useContext(ContactContext);

  function openPopup() {
    setOpen(true);
  }

  return (
    <Heading
      data={{
        title: 'Римские шторы',
        text: `Собственное производство, пошив&nbsp;по&nbsp;вашим&nbsp;размерам`,
        img: mainImg,
        btns: [
          {
            label: 'Каталог',
            link: `/${CATALOG_MAIN_LINK}`,
          },
          {
            label: 'Оставить заявку',
            onClick: openPopup,
          },
        ],
        color: '#554841',
      }}
    />
  );
}

export function HeadingZamer() {
  return (
    <Heading
      data={{
        title: 'Замер',
        text: `Как правильно сделать замер&nbsp;Римской&nbsp;шторы?`,
        img: zamerImg,
        color: '#8C9199',
      }}
    />
  );
}

export function HeadingPortfolio() {
  const { setContactPopupOpen: setOpen } = useContext(ContactContext);

  function openPopup() {
    setOpen(true);
  }

  return (
    <Heading
      data={{
        title: 'Наши Работы',
        text: `У нас более 2356 довольных&nbsp;клиентов`,
        img: portfolioImg,
        btns: [
          {
            label: 'Оставить заявку',
            onClick: openPopup,
          },
        ],
        color: '#70A0A4',
      }}
    />
  );
}

function Heading({ data }) {
  return (
    <div className="heading" style={{ background: data.color }}>
      <div className="heading__container">
        <div className="heading__content">
          <h1 className="heading__title">{data.title}</h1>
          <p
            className="heading__subtitle"
            dangerouslySetInnerHTML={{ __html: data.text }}
          />
          {data.btns ? (
            <div className="heading__btns-block">
              {data.btns.map((item, i) =>
                item.link ? (
                  <Link
                    className="heading__btn heading__btn_type_link"
                    to={item.link}
                    key={i}
                  >
                    {item.label}
                  </Link>
                ) : item.onClick ? (
                  <button
                    className="heading__btn"
                    type="button"
                    onClick={item.onClick}
                    key={i}
                  >
                    {item.label}
                  </button>
                ) : null
              )}
            </div>
          ) : null}
        </div>
        <img className="heading__img" src={data.img} alt="" />
      </div>
    </div>
  );
}
